// The goal is to remove svelte components that have previously been mounted, when rendering a page with turbo

export const setupSvelteComponents = (customRequireFn, namespace) => {
  if (
    window.initializedSvelteComponents &&
    window.initializedSvelteComponents[namespace]
  ) {
    window.initializedSvelteComponents[namespace].forEach((component) => {
      if (
        component.$$.root.parentElement?.dataset.turboPermanent == undefined
      ) {
        component.$destroy();
      }
    });
  }

  if (window.initializedSvelteComponents == undefined) {
    window.initializedSvelteComponents = [];
  }

  window.initializedSvelteComponents[namespace] = [];

  for (const domComponent of document.querySelectorAll(
    "[svelte-root-component]"
  )) {
    const componentName =
      domComponent.attributes["svelte-root-component"].value;
    const componentNamespace = domComponent.attributes["namespace"].value;

    if (namespace != componentNamespace) {
      continue;
    }

    domComponent.childNodes.forEach((el) => {
      el.remove();
    });

    // this loads all components matching the regex, at compile time
    // See https://webpack.js.org/guides/dependency-management/
    // See https://stackoverflow.com/questions/50173147/webpack-dynamic-require
    const requiredApp = customRequireFn(componentName);
    const props = domComponent.getAttribute("data-props") || "{}";

    const svelteComponent = new requiredApp.default({
      target: domComponent,
      props: JSON.parse(props),
    });

    window.initializedSvelteComponents[namespace].push(svelteComponent);
  }
};

export const createEventListeners = (namespace, customRequireFn) => {
  if (window.svelteComponentsSetup == undefined) {
    window.svelteComponentsSetup = {};
  }
  document.addEventListener("turbo:before-visit", () => {
    window.svelteComponentsSetup[namespace] = false;
  });

  document.addEventListener("turbo:before-render", () => {
    window.svelteComponentsSetup[namespace] = false;
  });

  document.addEventListener("turbo:render", () => {
    if (!window.svelteComponentsSetup[namespace]) {
      setupSvelteComponents(customRequireFn, namespace);
      window.svelteComponentsSetup[namespace] = true;
    }
  });

  document.addEventListener("turbo:load", () => {
    if (!window.svelteComponentsSetup[namespace]) {
      setupSvelteComponents(customRequireFn, namespace);
      window.svelteComponentsSetup[namespace] = true;
    }
  });
};
