<script>
  import Carousel from 'svelte-carousel'
  import Visibility from '../common/Visibility.svelte'
  import { fade } from 'svelte/transition';

  export let imageUrls = []
  export let chevronLeft, chevronRight

  const particlesToShow = document.body.clientWidth < 640 ? 1 : 3

</script>

{#if imageUrls.length > 1 }
  <Visibility threshold={100} let:visible let:percent>
    {#if visible}
      <Carousel let:loaded particlesToShow={particlesToShow} let:showPrevPage let:showNextPage dots={false} autoplay={true} >

        <div slot="prev" class="absolute z-20 items-center justify-center hidden w-8 h-8 -mt-4 bg-gray-100 rounded-full cursor-pointer select-none md:flex top-1/2 left-4" on:click={showPrevPage}>
          <div class= "w-6 h-6 fill-current text-primary">
            {@html chevronLeft}
          </div>
        </div>

        {#each imageUrls as src, imageIndex (src)}
          <div in:fade="{{duration: 600 * imageIndex}}" class="w-full">
            {#if loaded.includes(imageIndex)}
              <img {src} alt="" />
            {/if}
          </div>
        {/each}

        <div slot="next" class="absolute z-20 items-center justify-center hidden w-8 h-8 -mt-4 bg-gray-100 rounded-full cursor-pointer select-none md:flex top-1/2 right-4" on:click={showNextPage}>
          <div class= "w-6 h-6 fill-current text-primary">
            {@html chevronRight}
          </div>
        </div>
      </Carousel>
    {/if}
  </Visibility>
{/if}
